<template>
  <div>
    <quillEditor
        @input="handleInput"
        :value="value"
        :options="options"
        class="editor-container"
    />
  </div>

</template>

<script>


import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  name: 'VTextEditor',
  components: {
    quillEditor
  },
  props: {
    value: {
      type: String,
      required: true
    },
    minHeight: {
      type: String,
      default: '150px'
    },
    allowMediaFiles: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleInput(v) {
      this.$emit('input', v)
    }
  },
  computed: {
    style() {
      return {
        minHeight: this.minHeight
      }
    },
    toolbarOptions() {
      return [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'size': ['small', false, 'large'] }],
        [{ 'header': [1, 2, 3, 4, 5] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean'],
        this.allowMediaFiles ?  ['link', 'image', 'video'] : []
      ]
    },
    options() {
      return {
        theme: 'snow',
        boundary: document.body,
        modules: {
          toolbar: this.toolbarOptions
        },
        placeholder: 'Insert text here ...',
        readOnly: true
      }
    }
  }
}
</script>

<style lang="scss">
.editor-container {
  .ql-container {
    min-height: 150px;
  }
}
.ql-container {
  border-radius: 0 0 0.5em 0.5em;
}
.ql-toolbar {
  border-radius: 0.5em 0.5em 0 0;
}
</style>
