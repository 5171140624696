<script>
import { fetchUnitPreviewData } from '../../client/unit-page/services/unit-sevice'
import {UNIT_TYPE_FAMILY} from '../../admin/units/shared/constants/units-constants'

export default {
  name: 'UnitInfo',
  render() {
    return this.$scopedSlots.default({
      unitTitle: this.unit.title,
      unitType: this.unit.unitType
    })
  },
  props: {
    unitId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      unit: {
        unitType: UNIT_TYPE_FAMILY
      },
      isLoading: false
    }
  },
  created() {
    this.fetchInfo()
  },
  methods: {
    fetchInfo() {
      this.isLoading = true
      fetchUnitPreviewData(this.unitId)
        .then((res) => {
          this.unit = res
        })
        .catch(() => {
          this.$message({
            message: `${this.$t('components.unit_info.alerts.error')}`,
            type: 'error'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
