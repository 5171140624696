<template>
  <UnitPreviewInfo :unit-id="$route.params.unitID"
                   #default="{unitTitle}"
  >
    <div class="unit-info">
      <div class="d-flex align-center unit-info__item">
        <v-icon color="#606266" name="home"/>
        <span>
            {{ $t('page_adoption_payment.card_info.unit_title') }}
        </span>
        <span>
            &nbsp;-&nbsp;
        </span>
        <span class="font-semi-bold">
            {{ unitTitle }}
        </span>
      </div>
    </div>
  </UnitPreviewInfo>
</template>

<script>
import UnitPreviewInfo from '../../../../components/units/UnitPreviewInfo'
import VIcon from '../../../../components/ui/v-icon/VIcon'
export default {
  name: 'CurrentUnitInfo',
  components: {VIcon, UnitPreviewInfo}
}
</script>
